import { Button, Form, notification } from 'antd';
import React, { useContext, useEffect, useState } from 'react'
import authContext from '../../../../components/Shared/Auth.service';
import { MetamaskService } from '../../../../components/Shared/Metamask.service';
import { AdminService } from '../../../../components/services/AdminService/Admin.service';
import { CommissionsService } from '../../../../components/Shared/Blockchain/commissions/commissions.service';

const useSelectedWalletContext = () =>
  new MetamaskService().useSelectedWalletContext();
const adminService = new AdminService();
const commissionsService = new CommissionsService();

function CommissionConfigForGoldenBadge(props) {
  const defaultCommissionForMarketplace = 10;
  const { loading, setLoading, config, smartContractData, todayEtheriumPriceInUSD, loadConfiguration=()=>{}} = props || {}
  const [form] = Form.useForm();
  const { userInfo } = useContext(authContext);
  const { networkId } = useSelectedWalletContext();
  const [goldenBadgeCommission, setGoldenBadgeCommission] = useState(defaultCommissionForMarketplace);
  const [goldenBadgeListingFees, setGoldenBadgeListingFees] = useState();
  const [goldenBadgeMintingFees, setGoldenBadgeMintingFees] = useState();
  const [goldenBadgeRoyalty, setGoldenBadgeRoyalty] = useState(defaultCommissionForMarketplace);
  const [goldenBadgeCollectionFee, setGoldenBadgeCollectionFee] = useState();
  const [goldenBadgeSellingFees, setGoldenBadgeSellingFees] = useState();

  useEffect(() => {
    setGoldenBadgeCollectionFee(config?.goldenBadgeCollectionFee);
    setGoldenBadgeSellingFees(config?.goldenBadgeSellingFees);
    setGoldenBadgeCommission(config?.goldenBadgeCommission ?? defaultCommissionForMarketplace);
    setGoldenBadgeListingFees(config?.goldenBadgeListingFees);
    setGoldenBadgeMintingFees(config?.goldenBadgeMintingFees);
    setGoldenBadgeRoyalty(parseInt(config?.goldenBadgeRoyalty ?? defaultCommissionForMarketplace));
  }, [config, defaultCommissionForMarketplace])

  const formItemLayout = {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 8 },
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 16 },
    },
  };

  const tailFormItemLayout = {
    wrapperCol: {
      xs: {
        span: 24,
        offset: 0,
      },
      sm: {
        span: 16,
        offset: 8,
      },
    },
  };

  const updateGoldenBadgeCommission = async () => {

    if (!smartContractData?.commissionsContract) {
      notification.open({
        message: 'Error',
        description: 'Marketplace contract not found',
        duration: 0,
      });
      return;
    }

    setLoading(true);
    if (
      !goldenBadgeCommission ||
      !goldenBadgeListingFees ||
      !goldenBadgeRoyalty ||
      !goldenBadgeCollectionFee
    )
      return notification.open({
        message: "Please do fill in the Feilds",
        description: "something went wrong while updating data",
        duration: 0,
      });

    try {
      const txnReceipt = await commissionsService.setCommissionAndFees_GB(
        String(userInfo?.walletAddress),
        String(goldenBadgeCollectionFee),
        String(goldenBadgeMintingFees),
        String(goldenBadgeListingFees),
        goldenBadgeCommission,
        goldenBadgeRoyalty,
        String(goldenBadgeSellingFees),
        String(smartContractData?.commissionsContract)
      );

      console.log("Blockchain Transaction receipt: ", txnReceipt);
      if (txnReceipt) {
        let form1 = {
          userId: userInfo._id,
          type: "Updating-commissions-GoldenBadgeUser",
          goldenBadgeCommission: parseInt(goldenBadgeCommission),
          goldenBadgeMintingFees: goldenBadgeMintingFees,
          goldenBadgeRoyalty: parseInt(goldenBadgeRoyalty),
          goldenBadgeCollectionFee: goldenBadgeCollectionFee,
          goldenBadgeListingFees: goldenBadgeListingFees,
          goldenBadgeSellingFees: goldenBadgeSellingFees,
          MPsuperAdmin: userInfo?.walletAddress,
          transactionDetail: txnReceipt,
          networkId
        };
        let res = await adminService.setMarketPlaceConfiguration(form1);
        if (res.data) {
          notification.open({
            message:
              "Successfully updated commission and fees for GoldenBadge Holder",
            duration: 0,
          });
          setLoading(false);
          loadConfiguration();
        }
      }
    } catch (error) {
      setLoading(false);
      console.log(error);
      notification.open({
        message: error.message,
        description: "something went wrong while updating data",
        duration: 0,
      });
    }
  };

  return (
    !loading && <>
      <p className='market-super-admin commission-configuration-text'>Configuration for Golden Badge</p>
      <div className='market-super-admin card-container d-flex justify-content-center'>
        <div className='market-super-admin card-inner-container' style={{ width: '100%', padding: '40px 20px' }}>
          <Form
            {...formItemLayout}
            form={form}
            onFinish={updateGoldenBadgeCommission}
          >
            <Form.Item
              label="Golden Badge Listing Fees in Matic"
              name="listingFees"
              rules={[
                {
                  required: false,
                  message: "Please input Listing Fees in Matic!",
                },
              ]}
            >
              <input
                type="text"
                inputMode='decimal'
                pattern="^[0-9]*[.,]?[0-9]*$"
                defaultValue={goldenBadgeListingFees}
                value={goldenBadgeListingFees}
                onChange={(e) => {
                    const value = e.target.value;
                    if (/^[0-9]*[.,]?[0-9]*$/.test(value)) {
                      setGoldenBadgeListingFees(value);
                          }
                     }}
                style={{ padding: "2px 5px 2px 5px" }}
              />
              {goldenBadgeListingFees && (
                <span style={{ alignItems:"center" }}>
                  USD:{" "}
                  {(
                    goldenBadgeListingFees *
                    todayEtheriumPriceInUSD
                  )}
                </span>
              )}
            </Form.Item>

            <Form.Item
              label="Golden Badge Minting Fees in Matic"
              name="MintingFees"
              rules={[
                {
                  required: false,
                  message: "Please input Minting Fees in Matic!",
                },
              ]}
            >
              <input
                type="text"
                inputMode='decimal'
                pattern="^[0-9]*[.,]?[0-9]*$"
                defaultValue={goldenBadgeMintingFees}
                value={goldenBadgeMintingFees}
                onChange={(e) => {
                    const value = e.target.value;
                    if (/^[0-9]*[.,]?[0-9]*$/.test(value)) {
                      setGoldenBadgeMintingFees(value);
                          }
                     }}
                style={{ padding: "2px 5px 2px 5px" }}
              />
              {goldenBadgeMintingFees && (
                <span style={{ alignItems:"center" }}>
                  USD:{" "}
                  {(
                    goldenBadgeMintingFees *
                    todayEtheriumPriceInUSD
                  )}
                </span>
              )}
            </Form.Item>


            <Form.Item
              label="Golden Badge Collection Fee in Matic"
              name="goldenBadgeCollectionFee"
              rules={[
                {
                  required: false,
                  message:
                    "Please input Collection Fee in Matic!",
                },
              ]}
            >
              <input
                type="text"
                inputMode='decimal'
                pattern="^[0-9]*[.,]?[0-9]*$"
                defaultValue={goldenBadgeCollectionFee}
                value={goldenBadgeCollectionFee}
                onChange={(e) => {
                    const value = e.target.value;
                    if (/^[0-9]*[.,]?[0-9]*$/.test(value)) {
                      setGoldenBadgeCollectionFee(value);
                          }
                     }}
                style={{ padding: "2px 5px 2px 5px" }}
              />
              {goldenBadgeCollectionFee && (
                <span style={{ alignItems:"center" }}>
                  USD:{" "}
                  {(
                    goldenBadgeCollectionFee *
                    todayEtheriumPriceInUSD
                  )}
                </span>
              )}
            </Form.Item>

            <Form.Item
              label="Golden Badge Selling Commission in Percentage"
              name="goldenBadgeSellingFees"
              rules={[
                {
                  required: false,
                  message:
                    "Please input Golden Badge Selling Commission in Percentage!",
                },
              ]}
            >
              <input
                type="number"
                defaultValue={goldenBadgeSellingFees}
                value={goldenBadgeSellingFees}
                onChange={(e) =>
                  setGoldenBadgeSellingFees(e.target.value)
                }
                style={{ padding: "2px 5px 2px 5px" }}
              /><span style={{alignItems:"center"}}>{"%"}</span>
            </Form.Item>

            {smartContractData.deployed === true && (<Form.Item {...tailFormItemLayout}>
              <Button
                type="primary"
                htmlType="submit"
                loading={loading}
                className='market-place-admin update-button'
              >
                Update
              </Button>
            </Form.Item>)}
          </Form>
        </div>
      </div>
    </>
  )
}

export default CommissionConfigForGoldenBadge
