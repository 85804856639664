import { Avatar, Tabs, notification } from "antd"
import { useContext, useEffect, useState } from "react";
import '../pages/styles/profileDetails.scss';
import authContext from "../components/Shared/Auth.service";
import Spinner from "../components/spinner/Spinner";
import { MetamaskService } from "../components/Shared/Metamask.service";
import { NFTCreatorService } from "../components/services/NFTCreator/NFTCreator.service";
import { SharedService } from "../components/Shared/Shared.service";
import "../pages/Profile/index.css";
import { useHistory } from "react-router-dom";
import { NftDrafted } from "./nftDrafted";

const useSelectedWalletContext = () =>
  new MetamaskService().useSelectedWalletContext();
const nftService = new NFTCreatorService();
const sharedService = new SharedService();

export const NftProfile = () => {
  const { userInfo } = useContext(authContext);
  const { networkId } = useSelectedWalletContext();
  const history = useHistory();

  const [loading, setLoading] = useState(true);
  const [createdNFT, setCreatedNFT] = useState([]);
  const [mintedNFT, setMintedNFT] = useState([]);
  const [soldNFT, setSoldNFT] = useState([]);
  const [boughtNFT, setBoughtNFT] = useState([]);
  const [pendingNFT, setPendingNFT] = useState([]);
  const [rejectedNFT, setRejectedNFT] = useState([]);
  const [todayEtheriumPriceInUSD, setTodayEtheriumPriceInUSD] = useState();
  const [biddedNFT, setBiddedNFT] = useState([]);
  const [tab, setTab] = useState('nftDrafted')

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(async () => {
    setLoading(true);

    if (!networkId) return;

    setTimeout(async () => {
      if (userInfo?._id) {
        //setting ether price
        try {
          setTodayEtheriumPriceInUSD(await sharedService.getEtheriumPrice());
        } catch (error) {
          console.error(error);
          notification.open({
            message: "Error",
            description:
              "something went wrong while fetching ether conversion api!",
            duration: 0,
          });
        }

        if (userInfo?.status?.KYC && userInfo?.status?.KYC === "pending") {
          return history.push("/KYCUpload");
        }

        let res = await nftService.getUserNFT(userInfo?._id, { networkId });
        let nfts = res.data;
        if (nfts) {
          if (nfts.created?.length > 0) {
            setCreatedNFT(nfts.created);
          }
          if (nfts.pending?.length > 0) {
            setPendingNFT(nfts.pending);
          }
          if (nfts.rejected?.length > 0) {
            setRejectedNFT(nfts.rejected);
          }
          if (nfts.minted?.length > 0) {
            setMintedNFT(nfts.minted);
          }
          if (nfts.sold?.length > 0) {
            setSoldNFT(nfts.sold);
          }
          if (nfts.bought?.length > 0) {
            setBoughtNFT(nfts.bought);
          }
          if (nfts.bidded?.length > 0) {
            setBiddedNFT(nfts.bidded);
          }
        }
        res = nftService.getMinimumNoOfFollowersForGoldenBadge(userInfo?._id);
        if (res.data) {
          // setLinkedinGoldenBadge(res.data.linkedinGoldenBadge);
          // setTwitterGoldenBadge(res.data.twitterGoldenBadge);
        }
        setTimeout(() => setLoading(false), 400);
      } else {
        return history.push("/account/login");
      }
    }, 400);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userInfo, networkId]);

  const onChange = (key) => {
    setTab(key);
  };

  const items = [
    {
      key: 'nftDrafted',
      label: 'NFT Drafted'
    },
    {
      key: 'nftPending',
      label: 'NFT Pending',
    },
    {
      key: 'nftRejected',
      label: 'NFT Rejected'
    },
  ];

  const renderTabs = (val) => {
    switch (val) {
      case "nftDrafted":
        return <NftDrafted nftCardBtnText={"Place for Admin Verification"} draftedNft={true} fromTab={true} nftDraftArr={createdNFT} todayEtheriumPriceInUSD={todayEtheriumPriceInUSD} noNftText={"You have no drafted NFT in your box "} />
      case "nftRejected":
        return <NftDrafted nftCardBtnText={"Admin Rejected"} fromTab={true} nftDraftArr={rejectedNFT} todayEtheriumPriceInUSD={todayEtheriumPriceInUSD} noNftText={"You have no rejected NFT in your box "} />
      case "nftPending":
        return <NftDrafted nftCardBtnText={"Pending"} fromTab={true} nftDraftArr={pendingNFT} todayEtheriumPriceInUSD={todayEtheriumPriceInUSD} noNftText={"You have no pending NFT in your box "} />
      default:
        return null;
    }
  }

  return <>
    {loading && <Spinner />}

    {/* Header  */}
    <div className="profile-section banner d-flex justify-content-start align-items-start">
      <div className="d-flex" style={{ width: "100%" }}>
        <div className="profile-div avatar col-lg-3 col-md-6 col-sm-12">
          <Avatar className="profile-div avatar-image" src={userInfo?.profilePicUrl}></Avatar>
        </div>
        <div className="profile-div user-details col-lg-4 col-md-6 col-sm-12">
          <p className="profile-div user-name">{`${userInfo?.firstName} ${userInfo?.lastName}`}</p>
          <p className="profile-div user-email">{userInfo?.email}</p>
          <p className="profile-div wallet-address-text">Wallet Address:</p>
          <p className="profile-div wallet-address">{userInfo?.walletAddress}</p>
        </div>
        <div className="profile-div progress-bar col-lg-5 col-md-6 col-sm-12">
          <div className="d-flex justify-content-between">
            <div className="d-flex flex-column profile-div progress-show">
              <p className="profile-div progress-count">{pendingNFT?.length || 0}</p>
              <p className="profile-div progress-text-bar">NFT Pending</p>
            </div>
            <div className="d-flex flex-column profile-div progress-show">
              <p className="profile-div progress-count">{soldNFT?.length || 0}</p>
              <p className="profile-div progress-text-bar">NFT Sold</p>
            </div>
            <div className="d-flex flex-column profile-div progress-show">
              <p className="profile-div progress-count">{biddedNFT?.length || 0}</p>
              <p className="profile-div progress-text-bar">NFT Bidded</p>
            </div>
          </div>
        </div>
      </div>
    </div>

    {/* Nft Tabs */}
    <div className="profile-section nft-tabs d-flex justify-content-start align-items-start">
      <Tabs defaultActiveKey="1" items={items} onChange={onChange} />
    </div>

    {renderTabs(tab)}

    {/* Nft Sold */}
    <div className="profile-section nft-tabs d-flex justify-content-start align-items-start">
      <p className="profile-div nft-sold-text">NFT Sold</p>
      <p className="profile-div nft-sold-sub-text">NFT Creator enters the price in Matic</p>
    </div>

    <div className="profile-div sold-nfts">
      <NftDrafted nftCardBtnText={"Sold"} nftDraftArr={soldNFT} todayEtheriumPriceInUSD={todayEtheriumPriceInUSD} noNftText={"You have no sold NFT in your box "} />
    </div>

    {/* NFT Bidded */}
    <div className="profile-section nft-tabs d-flex justify-content-start align-items-start">
      <p className="profile-div nft-sold-text">NFT Bidded</p>
      <p className="profile-div nft-sold-sub-text">NFT Creator Bids on NFT</p>
    </div>

    <div className="profile-div sold-nfts">
      <NftDrafted nftCardBtnText={"Check Details"} nftDraftArr={biddedNFT} todayEtheriumPriceInUSD={todayEtheriumPriceInUSD} noNftText={"You have no bidded NFT in your box "} />
    </div>

    <div className="d-flex" style={{width: '100%'}}>

      {/* NFT Bought */}
      <div className="col-lg-6 col-md-12 col-sm-12">
        <div className="profile-section nft-tabs d-flex justify-content-start align-items-start">
          <p className="profile-div nft-sold-text">NFT Bought</p>
          <p className="profile-div nft-sold-sub-text">You can Buy an NFT from Marketplace</p>
        </div>

        <div className="profile-div sold-nfts" style={{background: "#FFFFFF"}}>
          <NftDrafted sliderPoints={{ slidesToShow: 2,slidesToScroll: 2 }} nftCardBtnText={"Check Details"} nftDraftArr={boughtNFT} todayEtheriumPriceInUSD={todayEtheriumPriceInUSD} noNftText={"You have no bought NFT in your box "} />
        </div>
      </div>

      {/* NFT Minted */}
      <div className="col-lg-6 col-md-12 col-sm-12">
        <div className="profile-section nft-tabs d-flex justify-content-start align-items-start">
          <p className="profile-div nft-sold-text">NFT Minted</p>
          <p className="profile-div nft-sold-sub-text">Mint your NFT on Marketplace</p>
        </div>

        <div className="profile-div sold-nfts" style={{background: "#FFFFFF"}}>
          <NftDrafted sliderPoints={{ slidesToShow: 2,slidesToScroll: 2 }} nftCardBtnText={"Check Details"} nftDraftArr={mintedNFT} todayEtheriumPriceInUSD={todayEtheriumPriceInUSD} noNftText={"You have no minted NFT in your box "} />
        </div>
      </div>

    </div>
    {/* <div className="row">
                        <div className="col-lg-10 offset-lg-1 d-flex">
                        <Formik
                            enableReinitialize
                            validationSchema={validationSchema}
                            initialValues={initialValues}
                            validateOnMount={validationSchema.isValidSync(initialValues)}
                            onSubmit={async (values, { setSubmitting, resetForm }) => {
                            setSubmitting(true);
                            await handleSubmitForm(values);
                            setSubmitting(false);
                            resetForm();
                            }}
                        >
                            { 
                                ({ values, isSubmitting, isValid }) => {

                                    return (
                                        <Form className="form-border w-100">
                                            <div className="de_tab tab_simple">
                                            
                                            <ul className="de_nav text-left m-0 mb-3">
                                                <li className="active" style={{opacity: 1}}><span><i className="fa fa-user"></i>Profile</span></li>
                                            </ul>
                                            
                                            <div className="de_tab_content">                            
                                                <div className="tab-1">
                                                    <div className="row wow fadeIn animated" style={{backgroundSize: 'cover', visibility: 'visible', animationName: 'fadeIn'}}>
                                                        <div className="col-lg-8 mb-sm-20">
                                                            <div className="field-set">
                                                                <h5>Username</h5>
                                                                <Field type="text" name="username" id="username" className="form-control" placeholder="Enter username"/>                                    
                                                                <ErrorMessage name="username" component="div" />
                                                                <div className="spacer-20"></div>

                                                                <h5>About</h5>
                                                                <Field component="textarea" name="about" id="about" className="form-control" placeholder="Tell the world who you are!"/>
                                                                <ErrorMessage name="about" component="div" />
                                                                <div className="spacer-20"></div>

                                                                <h5>Social</h5>
                                                                <Field type="text" name="social" id="social" className="form-control" placeholder="Enter Social URLs like Instagram or Twitter"/>
                                                                <ErrorMessage name="social" component="div" />
                                                                <div className="spacer-20"></div>

                                                                <h5>Wallet</h5>
                                                                <Field type="text" name="wallet" id="wallet" className="form-control" placeholder="Enter your Wallet Address"/>
                                                                <ErrorMessage name="wallet" component="div" />
                                                                <div className="spacer-20"></div>
                                                            </div>
                                                        </div>                                         
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <input type="submit" id="submit" className="btn-main" value="Update profile"/>
                                        </Form>
                                    )
                                }
                            }
                            </Formik>
                            </div>
                        </div>
                    </div> */}

  </>
}