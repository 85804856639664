/* eslint-disable no-const-assign */
import React, { useContext, useState, useEffect } from "react";
import { useHistory, useParams, Link } from "react-router-dom";
import { Spin, notification, message, Form, Button, Input, Divider, Table } from "antd";
import moment from "moment";
import Clock from "../components/Clock";
import { createGlobalStyle } from "styled-components";
import authContext from "../components/Shared/Auth.service";
import { NFTCreatorService } from "../components/services/NFTCreator/NFTCreator.service";
import { VideoPlayer } from "../components/mainSlider/VideoPlayer";
import { MetamaskService } from "./../components/Shared/Metamask.service";
import { SharedService } from "./../components/Shared/Shared.service";
import { PaymentService } from "./../components/services/Payment/Payment.service";
import ProfilePic from "./../assets/images/home/profile-default.png";
import eversign from "../components/Shared/Eversign/Eversign";
import { environment } from "../environments/environment";
import { NftTrader } from "../components/Shared/Blockchain/NftTrader/NftTrader.service";
import { AdminService } from "./../components/services/AdminService/Admin.service";
import "./styles/nftDetails.scss";

const nftService = new NFTCreatorService();
const paymentService = new PaymentService();
const sharedService = new SharedService();
const nftTrader = new NftTrader();
const nftCreatorService = new NFTCreatorService();
const adminService = new AdminService();

const useSelectedWalletContext = () =>
  new MetamaskService().useSelectedWalletContext();

const GlobalStyles = createGlobalStyle`
  header#myHeader.navbar.white {
    background: #FAF6F1;
    border-bottom: solid 1px #ccc !important;
  }
`;

const ItemBuy = function () {
  const { userInfo, setUserInfo } = useContext(authContext);
  //const walletBalance = useSelectedWallet("new investor")[6];
  // const walletAddress = useSelectedWallet("nft-user")[0];

  const { selectedWallet, networkId } = useSelectedWalletContext();

  const [NFTDetails, setNFTDetails] = useState(null);
  const [todayEtheriumPriceInUSD, setTodayEtheriumPriceInUSD] = useState();
  const [loading, setLoading] = useState(true);
  const [NFTPrice, setNFTPrice] = useState();
  const [bidField, showBidFeild] = useState(false);
  const [biddingPrice, setBiddingPrice] = useState();
  const [maxBiddingAmount, setMaxBiddingAmount] = useState();
  const [auctionStartingBidAmount, setAuctionStartingBidAmount] = useState();
  const [biddingDetails, setBiddingDetails] = useState([]);
  const [previouslyBidded, setPreviouslyBidded] = useState(false);
  const [allBiddedAmount, setAllBiddedAmount] = useState([]);
  const [inputError, setInputError] = useState(false);
  const [openEversign, setOpenEversign] = useState(false);
  const [signURL, setSignURL] = useState(null);
  const [form] = Form.useForm();
  const [biddingDone, setBiddineDone] = useState(false);
  const [bidWonUser, setBidWonUser] = useState(false);
  const [sellingFees, setSellingFees] = useState(0);
  const [sellingFeesGoldenBadge, setSellingFeesGoldenBadge] = useState(0);
  const [currentOwnerDetails, setCurrentOwnerDetails] = useState();
  const [generalListingCommission, setGeneralListingCommission] = useState();
  const [generalSellingCommission, setGeneralSellingCommission] = useState();
  const [minPriceError, setMinPriceError] = useState(false);
  const [blockchainNormalLoading, setBlockchainNormalLoading] = useState(false);
  const [blockchainPendingTransactionLoading, setBlockchainPendingTransactionLoading] = useState(false);
  const [blockchainInputGiven, setBlockchainInputGiven] = useState(false);
  const [blockchainInputHash, setBlockchainInputHash] = useState('');
  const [blockchainPendingTransactionDetails, setBlockchainPendingTransactionDetails] = useState();

  const { nftId } = useParams();
  const history = useHistory();

  const rowStyle = { height: '36px' };

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  }, []);

  useEffect(() => {
    setLoading(true);
    setTimeout(async () => {
      let data;
      if (userInfo?._id) data = (await nftService.getNFTById(nftId, userInfo?._id)).data;
      else data = (await nftService.getNFTById(nftId)).data;

      if (data) {
        setNFTDetails(data);
        setNFTPrice(data.sellingPrice ? data.sellingPrice : data.bid);
        if (data.biddingDone === true) setBiddineDone(true);
        if(!bidWonUser) {
          setTimeout(()=> setBidWonUser(data.bidWonUserId === userInfo?._id), 500);
        }
        const response = await adminService.getUsersDetailsById(data.userId);
        if (response.success && response.data) {
          setCurrentOwnerDetails(response.data);
        }
      }

      // setting all bidding details
      if (data.biddingDetails?.length > 0) {
        let biddingDetails = data.biddingDetails;
        let newBiddingDetails = [];
        let allBiddedAmountVar = [];

        // calling api to get user name and profile pic
        let userIds = [...biddingDetails.map((bid) => bid.userId)]?.join(",");
        let userDetailsArray = (
          await nftService.getUserSomeDetailsByUsersIds(userIds)
        ).data;
        if (userDetailsArray?.length > 0) {
          biddingDetails.forEach((bid) => {
            allBiddedAmountVar.push(parseFloat(bid?.price));
            let userMicroDetails = userDetailsArray.filter(
              (usr) => usr._id === bid.userId
            );
            if (userMicroDetails?.length > 0)
              newBiddingDetails.push({ ...bid, ...userMicroDetails[0] });
          });
          setBiddingDetails(newBiddingDetails);
          setAllBiddedAmount(allBiddedAmountVar);
        }

        // previously bidded by same user
        biddingDetails.forEach((bidDetails) => {
          if (userInfo?._id === bidDetails?.userId) {
            setPreviouslyBidded(true);
            setBiddingPrice(parseFloat(bidDetails?.price));
          }
        });
        setMaxBiddingAmount(
          Math.max(...biddingDetails.map((bid) => bid.price))
        );
      } else {
        if (data.method === "Auction")
          setAuctionStartingBidAmount(parseFloat(data.sellingPrice));
      }

      // setting ether price
      setTodayEtheriumPriceInUSD(await sharedService.getEtheriumPrice());
      setLoading(false);

      if (userInfo?._id && selectedWallet?.length > 0) {
        const [res3, res4] = await Promise.all([
          nftService.checkPendingTransactionByType(sharedService.pendingTransactionConstants.FINAL_PURCHASE, userInfo?._id, data?.collection?.collectionId, nftId),
          nftService.checkPendingTransactionByType(sharedService.pendingTransactionConstants.PLACE_A_BID, userInfo?._id, data?.collection?.collectionId, nftId)
        ]);

        if (res3?.data?.pendingTransaction) {
          setBlockchainPendingTransactionLoading(true);
          setBlockchainInputGiven(res3.data.inputGiven);
          if (res3.data.inputGiven) {
            setBlockchainInputHash(res3.data.blockchainHash);
          }
          setBlockchainPendingTransactionDetails(res3?.data);

          setLoading(true);
          setTimeout(() => window.location.reload(), 60000); //reload after 1 minute
        } else if (res4?.data?.pendingTransaction) {
          setBlockchainPendingTransactionLoading(true);
          setBlockchainInputGiven(res4.data.inputGiven);
          if (res4.data.inputGiven) {
            setBlockchainInputHash(res4.data.blockchainHash);
          }
          setBlockchainPendingTransactionDetails(res4?.data);

          setLoading(true);
          setTimeout(() => window.location.reload(), 60000); //reload after 1 minute
        }
      }
    }, 100);
  }, [userInfo, selectedWallet]);

  const doMetamaskTransaction = async () => {
    setLoading(true);
    setBlockchainNormalLoading(true);
    try {
      // here do the blockchain transaction with finalSellingFees for current owner and also with escrow contract address
      // let escrowContractAddress = NFTDetails?.escrowContractAddress;
      let txnReceipt;
      txnReceipt = await nftTrader.BuyNftOnFixedPrice(
        String(NFTDetails?.nftTradeContractAddress),
        String(NFTDetails?.collection?.NFTMintingContractAddress),
        String(NFTDetails?.tokenId),
        String(NFTDetails.sellingPrice),
        String(selectedWallet)
      );

      console.log("Blockchain Transaction receipt: ", txnReceipt);
      if (txnReceipt?.hash?.length > 0 || txnReceipt?.blockHash?.length > 0) {
        let blockchainPending = false;
        let blockchainHash = txnReceipt.hash || txnReceipt.blockHash;
        if (txnReceipt.pending && txnReceipt.pending === true) {
          console.log("txn_hash :", txnReceipt.hash);
          blockchainPending = true;
          blockchainHash = txnReceipt.hash || txnReceipt.blockHash;
        }

        let buyTransactionDetails = {
          nftId,
          transactionDetails: txnReceipt,
          nftSellerAddress: NFTDetails?.owner?.walletAddress,
          nftBuyerAddress: selectedWallet,
          nftSoldPrice: NFTDetails.sellingPrice,
          nftSellType: NFTDetails.method,
          blockchainPending,
          blockchainHash
        };

        completeFixedPricePurchase({ ...buyTransactionDetails });
      } else {
        notification.open({
          message: "Error",
          description:
            "Something went wrong, while initiating blockchain transaction",
          duration: 0,
        });
        return false;
      }
    } catch (error) {
      console.log(error);
      setLoading(false);
      setBlockchainNormalLoading(false);
      notification.open({
        message: "Error",
        description: error.message,
        duration: 0,
      });
    }
  };

  const completeFixedPricePurchase = async (buyTransactionDetails) => {
    setLoading(true);
    setBlockchainNormalLoading(true);
    let obj = {};
    //this will be ultimte buying price in etherium after all fees
    if (buyTransactionDetails?.buyingPrice) {
      obj.buyingPrice = buyTransactionDetails?.buyingPrice;
    } else {
      obj.buyingPrice = NFTDetails.price;
    }

    obj.previousOwnerId = NFTDetails.userId;

    obj.previousOwnerIds = NFTDetails.previousOwnerIds ? [...NFTDetails.previousOwnerIds, obj.previousOwnerId] : [obj.previousOwnerId];
    console.log('obj.previousOwnerIds', obj.previousOwnerIds);

    obj.allSellingStatus = [
      ...NFTDetails.allSellingStatus,
      {
        method: NFTDetails.method,
        buyingPrice: obj.buyingPrice,
      },
    ];

    obj.sold = true;
    obj.bought = true;
    obj.sellingStatus = "sold";
    if (obj.previousOwnerIds?.length > 1) obj.resold = true;

    obj.buyingPrice = obj.buyingPrice;
    obj.status = [
      ...NFTDetails.status,
      `soldBy ${NFTDetails.userId}`,
      `boughtBy ${userInfo?._id}`,
    ];

    obj.completePurchaseTxnDetails = buyTransactionDetails;
    obj.userId = userInfo?._id;
    obj.nftId = NFTDetails.nftId;

    obj.escrowContractCleared = true;
    obj.escrowContractStatus = {
      complianceAdminApproved: true,
      tokenAdminApproved: true,
      superAdminApproved: true
    }
    obj.soldDate = new Date().getTime();
    obj.blockchainPending = buyTransactionDetails.blockchainPending;
    obj.blockchainHash = buyTransactionDetails.blockchainHash;
    obj.userId = userInfo?._id;
    console.log('obj', obj);

    try {
      const response = await nftCreatorService.buyNFT({ ...obj, networkId });
      if (response.success && response.data) {
        notification.open({
          message: "This NFT has been Successfully Bought",
          duration: 0,
        });
        setTimeout(() => (window.location.href = `/item-buy/${NFTDetails.nftId}`), 500);
      }
    } catch (err) {
      notification.open({
        message: "error while buying nft. Please try again.",
        duration: 0,
      });
      console.error(err);
    }
    setLoading(false);
    setBlockchainNormalLoading(false);
  };


  const saveInputBlockchainHash = async () => {
    if (!blockchainInputHash || blockchainInputHash.length !== 66) {
      message.error("Please provide correct successfull blockchain hash or transactionId!");
      return false;
    }
    setLoading(true);

    try {
      const response = await nftCreatorService.addBlockchainHashToPendingTransaction({ inputBlockchainHash: blockchainInputHash, pendingTransactionId: blockchainPendingTransactionDetails?._id });
      if (response.success && response.data) {
        setBlockchainInputGiven(true);
        notification.open({
          message: response.data,
          duration: 0,
        });
        setTimeout(() => (window.location.reload()), 2000);
      } else {
        console.log('else block');
        message.error(response?.error?.code?.message);
        console.error(response?.error?.code);
      }
    } catch (err) {
      message.error('Error while submitting blockchain hash to system. Please try again later!');
      console.log('catch block');
      console.error(err);
    }
  };

  const placeBid = async () => {
    let txnReceipt;
    let biddingTxnDetails;
    setMinPriceError(false);
    setBlockchainNormalLoading(true);
    setLoading(true);

    if (NFTDetails.method === "Auction") {
      txnReceipt = await nftTrader.bidOnAuction(
        String(NFTDetails?.nftTradeContractAddress),
        String(NFTDetails?.collection?.NFTMintingContractAddress),
        String(NFTDetails?.tokenId),
        String(biddingPrice),
        String(selectedWallet)
      );
    } else {
      // checking for minimum price of nft
      if (biddingPrice <= (generalListingCommission + generalSellingCommission)) {
        setMinPriceError(true);
        return false;
      }

      txnReceipt = await nftTrader.bidOnOpenForBids(
        String(NFTDetails?.nftTradeContractAddress),
        String(NFTDetails?.collection?.NFTMintingContractAddress),
        String(NFTDetails?.tokenId),
        String(biddingPrice),
        String(selectedWallet)
      );
    }

    console.log("Blockchain Transaction receipt: ", txnReceipt);
    let blockchainPending = false;
    let blockchainHash = txnReceipt?.hash || txnReceipt?.blockHash;
    if (txnReceipt?.hash?.length > 0 || txnReceipt?.blockHash?.length > 0) {
      if (txnReceipt.pending && txnReceipt.pending === true) {
        console.log("txn_hash pending :", txnReceipt);
        blockchainPending = true;
        blockchainHash = txnReceipt.hash || txnReceipt.blockHash;
      }

      biddingTxnDetails = {
        nftId,
        transactionDetails: txnReceipt,
        biddingPrice,
        biddedOn: NFTDetails.method,
        BiddedByWalletAddress: selectedWallet,
      };
    } else {
      notification.open({
        message: "Error",
        description:
          "Something went wrong, while initiating blockchain transaction",
        duration: 0,
      });
      return false;
    }

    let obj = {
      ownerId: NFTDetails?.userId,
      nftId: NFTDetails?.nftId,
      userId: NFTDetails?.userId,
      title: NFTDetails?.title,
      name: userInfo?.firstName + " " + userInfo?.lastName,
      price: parseFloat(biddingPrice),
    };
    let bidDetails = {
      userId: userInfo?._id,
      walletAddress: selectedWallet?.toLowerCase(),
      date: new Date(),
      timestamp: new Date().getTime(),
      price: parseFloat(biddingPrice),
    };
    let nftNewData = (await nftService.getNFTById(nftId))?.data;
    let currentBiddingDetails = nftNewData?.biddingDetails;
    let newBiddingDetails = [];
    // if (previouslyBidded === true && NFTDetails.biddingDetails?.length > 0) {
    //   // first filter out all other bidding details
    //   let oldBiddingDetails = NFTDetails.biddingDetails?.filter(
    //     (bid) => bid.userId !== userInfo?._id
    //   );
    //   newBiddingDetails = [...oldBiddingDetails, bidDetails];
    // } else if (NFTDetails.biddingDetails?.length > 0) {
    //   newBiddingDetails = [...NFTDetails.biddingDetails, bidDetails];
    // } else {
    //   newBiddingDetails = [bidDetails];
    // }
    if (currentBiddingDetails?.length > 0) {
      newBiddingDetails = [...currentBiddingDetails, bidDetails];
    } else {
      newBiddingDetails = [bidDetails];
    }

    obj = { ...obj, biddingDetails: newBiddingDetails, biddingTxnDetails, userId: userInfo?._id, blockchainPending, blockchainHash };


    try {
      let res = await nftService.saveBidForNft({ ...obj, networkId });
      notification.open({
        message: `Successfully bidded the nft`,
        duration: 0,
      });
      setLoading(false);
      setBlockchainNormalLoading(false);
      setTimeout(() => window.location.reload(), 1000);
    } catch (error) {
      console.error(error);
      notification.open({
        message: "Error",
        description: "something went wrong while bidding nft",
        duration: 0,
      });
      setLoading(false);
      setBlockchainNormalLoading(false);
    }
  };

  const signSellBuyAgreement = async () => {
    try {
      setInputError(false);

      // input error check
      // current bidding less than previously bidded price
      if (NFTDetails.method !== "Fixed Price") {
        console.log("maxBiddingAmount is: ", maxBiddingAmount);
        if (
          isNaN(biddingPrice) ||
          (!isNaN(maxBiddingAmount) &&
            !(parseFloat(biddingPrice) > maxBiddingAmount)) ||
          (!isNaN(auctionStartingBidAmount) &&
            !(parseFloat(biddingPrice) >= auctionStartingBidAmount))
        ) {
          setInputError(true);
          return false;
        }
      }

      setLoading(true);
      const response = await nftService.getSellBuyNFTAgreementURL(
        userInfo?._id
      );
      if (response.data) {
        setSignURL(response.data);
        setOpenEversign(true);
      } else {
        message.error(
          "Something went wrong while fetching nft buy sell agreemnt url, please try again."
        );
      }
    } catch (error) {
      console.error(error);
      message.error(
        "Something went wrong while fetching nft buy sell agreement url, please try again."
      );
    }
    setLoading(false);
  };

  const signNFTAgreementSigned = async () => {
    setLoading(true);
    try {
      const response = await nftService.setSellBuyNFTAgreement({
        agreementType: "buy",
        userId: userInfo?._id,
        nftId: NFTDetails?._id,
        nftMethod: NFTDetails.method,
        agreementURL: signURL,
        creationTS: new Date().getTime(),
      });
      if (response.success && response.data) {
        setOpenEversign(false);
        notification.open({
          message: "Successfully signed NFT Sell Agreement",
          duration: 0,
        });

        if (NFTDetails.method === "Fixed Price") return doMetamaskTransaction();
        else return placeBid();
      }
    } catch (error) {
      console.error(error);
      message.error(
        "Something went wrong while submitting user agreement, please try again."
      );
      setLoading(false);
    }
  };

  const completeBiddingPurchase = async () => {
    setLoading(true);
    setBlockchainNormalLoading(true);
    let txnReceipt;
    let completePurchaseTxnDetails;

    // getting the correct owner whom creator wants to sell
    //let correctOwner = NFTDetails.biddingDetails.filter(bidDetails => bidDetails.walletAddress === selectedWallet);
    const filteredBiddingDetails = NFTDetails.biddingDetails.filter(bidDetails => bidDetails.walletAddress === selectedWallet);

    const lastBiddingDetail = filteredBiddingDetails?.length > 0 ? filteredBiddingDetails[filteredBiddingDetails.length - 1] : null;
    if (!lastBiddingDetail) {
      notification.open({
        message: "Error",
        description:
          "Can not get Last Bidding details! May be wrong wallet selected!",
        duration: 0,
      });
      return false;
    }

    console.log(`lastBiddingDetail: ${lastBiddingDetail}`);

    console.log('final purchase price is: ', lastBiddingDetail?.price);

    // here do the blockchain transaction with finalSellingFees for current owner
    if (NFTDetails.method === "Auction") {
      txnReceipt = await nftTrader.endAuction(
        String(NFTDetails?.nftTradeContractAddress),
        String(NFTDetails?.collection?.NFTMintingContractAddress),
        String(selectedWallet),
        //String(maxBiddingAmount),
        String(lastBiddingDetail?.price),
        String(NFTDetails?.tokenId),
      );
    } else if (NFTDetails.method === "Open for Bids") {
      txnReceipt = await nftTrader.sellToBidder(
        String(NFTDetails?.nftTradeContractAddress),
        String(NFTDetails?.collection?.NFTMintingContractAddress),
        String(NFTDetails?.tokenId),
        String(selectedWallet),
        String(lastBiddingDetail?.price)
      );
    }

    console.log("Blockchain Transaction receipt: ", txnReceipt);
    let blockchainPending = false;
    let blockchainHash = txnReceipt?.hash || txnReceipt?.blockHash;
    if (txnReceipt?.hash?.length > 0 || txnReceipt?.blockHash?.length > 0) {
      if (txnReceipt.pending && txnReceipt.pending === true) {
        console.log("txn_hash :", txnReceipt.hash);
        blockchainPending = true;
        blockchainHash = txnReceipt.hash || txnReceipt.blockHash;
      }

      completePurchaseTxnDetails = {
        nftId,
        transactionDetails: txnReceipt,
        nftSellType: NFTDetails.method,
        nftSellerAddress: NFTDetails?.owner?.walletAddress,
        nftBuyerAddress: selectedWallet,
        nftSoldPrice: lastBiddingDetail?.price,
        nftTokenId: NFTDetails?.tokenId,
      };
    } else {
      notification.open({
        message: "Error",
        description:
          "Something went wrong, while initiating blockchain transaction",
        duration: 0,
      });
      return false;
    }

    let obj = {};
    //this will be ultimte buying price in etherium after all fees
    obj.buyingPrice = lastBiddingDetail?.price;
    obj.sellingPrice = lastBiddingDetail?.price;

    obj.previousOwnerId = NFTDetails.userId;
    obj.previousOwnerIds = NFTDetails.previousOwnerIds ? [...NFTDetails.previousOwnerIds, obj.previousOwnerId] : [obj.previousOwnerId];
    console.log('obj.previousOwnerIds', obj.previousOwnerIds);

    obj.oldBiddingDetails = [...NFTDetails.oldBiddingDetails, [...NFTDetails.biddingDetails]];
    console.log('obj.oldBiddingDetails', obj.oldBiddingDetails);

    obj.allSellingStatus = [
      ...NFTDetails.allSellingStatus,
      {
        method: NFTDetails.method,
        buyingPrice: lastBiddingDetail?.price,
      },
    ];

    obj.sold = true;
    obj.bought = true;
    obj.sellingStatus = "sold";
    if (obj.previousOwnerIds?.length > 1) obj.resold = true;

    obj.status = [
      ...NFTDetails.status,
      `soldBy ${NFTDetails.userId}`,
      `boughtBy ${userInfo?._id}`,
    ];

    obj.userId = userInfo?._id;
    obj.nftId = NFTDetails.nftId;
    obj.completePurchaseTxnDetails = completePurchaseTxnDetails;

    obj.escrowContractCleared = false;
    obj.escrowContractStatus = {
      complianceAdminApproved: false,
      tokenAdminApproved: false,
      superAdminApproved: false
    }
    obj.previousBidderDetails = NFTDetails.biddingDetails.filter(bidDetails => bidDetails.walletAddress !== selectedWallet);
    obj.soldDate = new Date().getTime();
    obj.blockchainPending = blockchainPending;
    obj.blockchainHash = blockchainHash;
    obj.userId = userInfo?._id;
    console.log('obj', obj);

    try {
      const response = await nftCreatorService.buyNFT({ ...obj, networkId });
      if (response.success && response.data) {
        notification.open({
          message: "Successfully purchased the Nft!",
          duration: 0,
        });
        setTimeout(() => (window.location.href = `/item-buy/${NFTDetails.nftId}`), 500);
      }
    } catch (err) {
      notification.open({
        message: "error while buying nft. Please try again.",
        duration: 0,
      });
      console.error(err);
    }
    setLoading(false);
    setBlockchainNormalLoading(false);
  };

  const biddingDetailsColumns = [
    {
      title: 'User name',
      dataIndex: 'userName',
      key: 'userName',
      render: (text, record) => `${record.firstName} ${record.lastName}`,
    },
    {
      title: "Bidded",
      dataIndex: "price",
      key: "price",
      render: (text, record) => `${record.price} Matic`,
    },
    {
      title: "Date",
      dataIndex: "date",
      key: "date",
      render: (text) => moment(text).format("MMMM DD, YYYY hh:mm:ss A"),
    },
  ];

  const transformedData = biddingDetails.map((item) => ({
    ...item,
    userName: `${item.firstName} ${item.lastName}`,
  }));

  const biddingDetailsSource = transformedData || [];

  return (
    <div>
      <GlobalStyles />
      {loading && (<>
        <div className='loading-spinner' style={{ textAlign: 'center' }}>
          <br />
          <Spin style={{ size: 'large', textAlign: 'center' }} />
          {blockchainNormalLoading && (<h6>Please wait, Blockchain transaction is processing</h6>)}
          {blockchainPendingTransactionLoading && !blockchainNormalLoading && (<>
            {!blockchainInputGiven && (
              <h6>We are processing your transaction, please wait for Blockchain to complete the transaction. Still if you want to speed up the transaction, go on Metamask select the pending transaction, click on Speedup button. Select the Site Suggested as Aggressive. Then click on Confirm Button</h6>)}

            {blockchainInputGiven && (
              <h6>We are processing your new transaction, please wait for sometime!</h6>)}

            {!blockchainInputGiven && (<>
              <br />
              <Form
                name="blockchainInputForm"
                onFinish={saveInputBlockchainHash}
              >
                <Form.Item
                  label="Successfully Executed Blockchain Hash or TransactionId"
                  name="textInput"
                  rules={[{ required: true, message: 'Please input your TransactionId!' }]}
                  style={{ display: 'flex', justifyContent: 'center' }}
                >
                  <Input
                    type="text"
                    name="blockchainInputHash"
                    value={blockchainInputHash}
                    onChange={(e) =>
                      setBlockchainInputHash(e.target.value)
                    }
                    style={{ width: '400px' }}
                  />
                </Form.Item>

                <Form.Item>
                  <Button type="primary" htmlType="submit">
                    Submit
                  </Button>
                </Form.Item>
              </Form>
            </>)}
          </>)}
        </div>
      </>)}

      {!loading && NFTDetails && !openEversign && (
        <>
          <section className="container">
            <div className="row pt-md-4">
              <div className="col-md-6 text-center">
                <span className="img-detail-wrapper">
                  {NFTDetails.file.type === "mp4" ||
                    NFTDetails.file.type === "MP4" ? (
                    <VideoPlayer
                      linkSrc={
                        NFTDetails.file.watermarkedUrl
                          ? NFTDetails.file.watermarkedUrl
                          : NFTDetails.IPFSURL
                      }
                      //coverImageLink={NFTDetails.file?.videoWatermarkImageURL}
                      coverImageLink={NFTDetails.file?.videoThumbnailUrl}
                      playerId="player-1"
                      coverId="nft-cover"
                      playButtonId="play-1"
                      subsButtonId="subs-1"
                      height="350px"
                      width="100%"
                      coverImageHeight="350px"
                      autoplay="off"
                    />
                  ) : (
                    <img
                      src={
                        NFTDetails.file.watermarkedUrl
                          ? NFTDetails.file.watermarkedUrl
                          : NFTDetails.file.IPFSURL
                      }
                      className="img-fluid img-rounded mb-sm-30"
                      style={{ width: "100%" }}
                      alt=""
                    />
                  )}
                </span>

                {userInfo?.walletAddress?.toLowerCase() ===
                  selectedWallet?.toLowerCase() && <div className="d-flex flex-column">

                    {
                      biddingDetails?.length > 0 &&
                      <p className="nft-detail usersbids-text">{'List of users bids'}</p>
                    }

                    {
                      biddingDetails?.length > 0 &&
                      <p className="nft-detail bidded-amount"><span>{maxBiddingAmount ? 'Maximum Bidded amount by user :' : ''}</span>
                        {maxBiddingAmount && <span className="nft-detail ETH-val">{maxBiddingAmount} Matic</span>}
                      </p>
                    }

                    {
                      biddingDetails?.length > 0 &&
                      <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 table-border">
                        <Table className="nft-detail" columns={biddingDetailsColumns} dataSource={biddingDetailsSource} pagination={false} bordered rowStyle={() => rowStyle} />
                      </div>
                    }
                  </div>
                }

                {(NFTDetails.NFTDownload === "everyone" ||
                  NFTDetails.NFTDownload === "NFTOwner") && (
                    <>
                      <div
                        className="text-center"
                        style={{
                          marginTop: "20px",
                          display: "inline-block",
                          justifyContent: "center",
                        }}
                      >
                        <button
                          type="button"
                          className="btn-main download-nft-button"
                        >
                          <a
                            href={NFTDetails.file.watermarkedUrl}
                            download={NFTDetails.file.name}
                            target="_blank"
                          >
                            Download
                          </a>
                        </button>
                      </div>
                    </>
                  )}
              </div>
              <div className="col-md-6">
                <div className="nft-detail item_info">
                  {/* {NFTDetails.expirationDate && (<div className="de_countdown">
                                Auctions ends in
                                <Clock deadline={NFTDetails.expirationDate} />
                            </div>)} */}
                  <h2 className="nft-detail item_title">Title: {NFTDetails?.title}</h2>
                  <p className="nft-detail item_creator">Creator</p>
                  <div className="nft-detail item_creator_div">
                    <p className="nft-detail item_creator_name"> {NFTDetails.user?.firstName +
                      " " +
                      NFTDetails.user?.lastName}</p>
                    <img alt="profile pic" className="nft-detail profile-picurl" src={NFTDetails.user?.profilePicUrl
                      ? NFTDetails.user.profilePicUrl
                      : ProfilePic}></img>
                  </div>
                  <p className="nft-detail details-desc">Description: {NFTDetails?.desc}</p>
                  <p className="nft-detail collection-name" onClick={() =>
                    history.push(
                      `/collection/${NFTDetails?.collection?.collectionName}`,
                      "_self"
                    )
                  }>{'Collection name'}</p>


                  {/* Card 1 */}

                  <div className="nft-detail details-card1">
                    <div className="nft-detail d-flex details-card-pDiv" >


                      {NFTDetails.method === "Fixed Price" && (
                        <div className="nft-detail col-xl-6 col-lg-6 col-md-6 col-sm-12 current-price-div">
                          <p className="nft-detail current-price">{'Price'}</p>
                          <p className="nft-detail current-price-val">{NFTPrice} Matic</p>
                          <div className="nft-detail price-div">
                            <div>
                              <p className="nft-detail current-price">{'Price in USD'}</p>
                              <p className="nft-detail price-val">      {(NFTPrice * todayEtheriumPriceInUSD).toFixed(3) + " $"}</p>
                            </div>
                            <div style={{ marginLeft: '20px' }}>
                              <p className="nft-detail current-price">{'Method'}</p>
                              <p className="nft-detail price-val">{NFTDetails?.method}</p>
                            </div>
                          </div>
                        </div>
                      )}


                      {
                        NFTDetails.method === "Auction" && (
                          <div className="nft-detail col-xl-6 col-lg-6 col-md-6 col-sm-12 current-price-div">
                            <p className="nft-detail current-price">{'Min price'}</p>
                            <p className="nft-detail current-price-val">{NFTPrice} Matic</p>
                            <div className="nft-detail price-div">
                              <div>
                                <p className="nft-detail current-price">{'Min Price in USD'}</p>
                                <p className="nft-detail price-val">      {(NFTPrice * todayEtheriumPriceInUSD).toFixed(3) + " $"}</p>
                              </div>
                              <div style={{ marginLeft: '20px' }}>
                                <p className="nft-detail current-price">{'Method'}</p>
                                <p className="nft-detail price-val">{NFTDetails?.method}</p>
                              </div>
                            </div>
                          </div>
                        )
                      }

                      {NFTDetails.method === "Open for Bids" && (
                        <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 current-price-div">
                          <div>
                            <p className="nft-detail current-price">{'Method'}</p>
                            <p className="nft-detail price-val">{NFTDetails?.method}</p>
                          </div>
                        </div>
                      )}

                      {NFTDetails.method !== "Fixed Price" && <Divider className="nft-detail divider" />}

                      <div className="nft-detail col-xl-12 col-lg-12 col-md-12 col-sm-12" style={{ padding: '20px 0' }}>
                        {
                          NFTDetails.method === "Auction" && (
                            <>
                              <p className="nft-detail expirationDate">{'Expiration Date & Time: '}</p>
                              <p className="nft-detail expirationTime"> {moment(
                                new Date(NFTDetails.expirationTimestamp)
                              ).format(environment.displayTimeFormat)}</p>
                            </>
                          )
                        }
                        {(NFTDetails.method === "Auction" ||
                          NFTDetails.method === "Open for Bids") && (
                            <>
                              <p className="nft-detail offerValidityTime">
                                Time in Days by which buyer has to do the final NFT
                                purchase after winning Bids or Auction:{" "}
                                <b>{NFTDetails.offerValidityTime || 3}</b> days
                              </p>
                            </>
                          )}

                      </div>

                    </div>
                  </div>


                  <h5 className="nft-detail owner-text" style={{ marginTop: '16px !important' }}>Owner</h5>

                  <div className="nft-detail item_author">
                    <div className="nft-detail author_list_pp">
                      <span>
                        <img
                          className="lazy"
                          src={
                            currentOwnerDetails?.profilePicUrl
                              ? currentOwnerDetails.profilePicUrl
                              : ProfilePic
                          }
                          alt=""
                        />
                        <i className="fa fa-check"></i>
                      </span>
                    </div>
                    <div className="author_list_info">
                      <span>
                        {currentOwnerDetails?.firstName +
                          " " +
                          currentOwnerDetails?.lastName}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-12">
                {!userInfo?._id && (
                  <p
                    style={{
                      color: "black",
                      marginTop: "10%",
                      marginLeft: "20%",
                    }}
                  >
                    You need to <Link to="/account/login">Login</Link> to Buy or
                    Place A Bid for this NFT!
                  </p>
                )}
                {/* <ul className="de_nav">
                            {/* <li id='mainbtn1' className="active"><span onClick={()=>handleBtnClick('cryptocurrency')}>Cryptocurrency</span></li> 
                            <li id='mainbtn2' className='active'><span onClick={()=>handleBtnClick('metamask')}>Metamask</span></li>
                        </ul> */}
                <br />
                <div className="text-center">
                  {userInfo?.walletAddress?.toLowerCase() ===
                    selectedWallet?.toLowerCase() && (
                      <div className="tab-1 onStep fadeIn">

                        {auctionStartingBidAmount && (
                          <>
                            <p className="nft-detail bidded-amount" style={{ marginTop: '20px', justifyContent: 'center' }}>
                              <span>{'Auction Starting Amount is'}</span>
                              <span className="nft-detail ETH-val">{auctionStartingBidAmount} Matic</span>
                            </p>
                          </>
                        )}

                        <div className="p_list">
                          <div className="p_list_info">
                            {/* Fixed Price */}
                            {NFTDetails.method === "Fixed Price" && userInfo?._id &&
                              NFTDetails.userId !== userInfo?._id && NFTDetails.createdBy !== userInfo?._id && (
                                <div
                                  className="text-center"
                                  style={{
                                    marginTop: "20px",
                                    display: "inline-block",
                                    justifyContent: "center",
                                  }}
                                >
                                  <button
                                    type="button"
                                    className="btn-main download-nft-button"
                                    style={{ backgroundColor: "blueviolet" }}
                                    onClick={signSellBuyAgreement}
                                  >
                                    <span>Buy Now</span>
                                  </button>
                                </div>
                              )}

                            {/* NFT Bidding or Auction */}
                            {(NFTDetails.method === "Open for Bids" ||
                              NFTDetails.method === "Auction") &&
                              userInfo?._id && (
                                <>
                                  {!biddingDone && !bidField && NFTDetails.sellingStatus === "selling" &&
                                    NFTDetails.userId !== userInfo?._id && NFTDetails.createdBy !== userInfo?._id && (
                                      <div
                                        className="text-center"
                                        style={{
                                          marginTop: "20px",
                                          display: "inline-block",
                                          justifyContent: "center",
                                        }}
                                      >
                                        <button
                                          type="button"
                                          className="btn-main download-nft-button"
                                          style={{
                                            backgroundColor: "blueviolet",
                                          }}
                                          onClick={() => showBidFeild(true)}
                                        >
                                          <span>
                                            {previouslyBidded
                                              ? "Click here to Place a Bid Again!"
                                              : "Click here to Place a Bid"}
                                          </span>
                                        </button>
                                      </div>
                                    )}
                                  <br />
                                  {!biddingDone &&
                                    bidField &&
                                    NFTDetails.sellingStatus === "selling" && (
                                      <>
                                        <br />
                                        <div className="col-12">
                                          <Form
                                            form={form}
                                            onFinish={signSellBuyAgreement}
                                            style={{
                                              display: "inline-block",
                                              justifyContent: "center",
                                            }}
                                          >
                                            <Form.Item
                                              label="Enter Bid Amount in Matic(decimal)"
                                              rules={[
                                                {
                                                  required: true,
                                                  message:
                                                    "Please Enter Bid Amount in Matic",
                                                },
                                              ]}
                                            >
                                              <input
                                                type="text"
                                                name="bidAmount"
                                                defaultValue={biddingPrice}
                                                value={biddingPrice}
                                                onChange={(e) =>
                                                  setBiddingPrice(e.target.value)
                                                }
                                                style={{
                                                  marginRight: "20px",
                                                  padding: "0 5px",
                                                }}
                                              />
                                              USD:{" "}
                                              {biddingPrice
                                                ? (
                                                  biddingPrice *
                                                  todayEtheriumPriceInUSD
                                                ).toFixed(3)
                                                : ""}
                                            </Form.Item>
                                            {inputError && (
                                              <p style={{ color: "red" }}>
                                                Please give a valid amount in
                                                digits or decimal or You can not
                                                place a bid with same or less
                                                amount which is already bidded by
                                                some other user previously or less
                                                than minimum price!
                                              </p>
                                            )}

                                            {minPriceError && (<h6 style={{ color: 'orangered' }}>You can not list your NFT with minimum price less than or equals to {(generalListingCommission + generalSellingCommission)} ether</h6>)}

                                            <Form.Item>
                                              <Button
                                                type="primary"
                                                htmlType="submit"
                                                loading={loading}
                                              >
                                                Submit
                                              </Button>
                                            </Form.Item>
                                          </Form>
                                        </div>
                                      </>
                                    )}

                                  {biddingDone &&
                                    NFTDetails.sellingStatus === "selling" &&
                                    !bidWonUser && (
                                      <h6>
                                        {NFTDetails.method === 'Auction' ? ("Auction has been closed for this NFT!") : ("Bidding has been closed for this NFT!")}
                                      </h6>
                                    )}

                                  {biddingDone &&
                                    NFTDetails.sellingStatus === "selling" &&
                                    bidWonUser && (
                                      <div
                                        className="text-center"
                                        style={{
                                          marginTop: "20px",
                                          display: "inline-block",
                                          justifyContent: "center",
                                        }}
                                      >
                                        <button
                                          type="button"
                                          className="btn-main download-nft-button"
                                          style={{
                                            backgroundColor: "blueviolet",
                                          }}
                                          onClick={completeBiddingPurchase}
                                        >
                                          <span>Complete Purchase</span>
                                        </button>
                                      </div>
                                    )}
                                </>
                              )}
                          </div>
                        </div>
                      </div>
                    )}
                </div>
              </div>
            </div>
          </section>
        </>
      )}

      {!loading && openEversign && (
        <div hidden={!openEversign} id="embedSignDivision">
          {openEversign &&
            setTimeout(
              () =>
                eversign.open({
                  url: signURL,
                  containerID: "embedSignDivision",
                  width: 750,
                  height: 800,
                  events: {
                    loaded: () => {
                      console.log("loading successful");
                    },
                    signed: signNFTAgreementSigned,
                    error: () => console.log("EVERSIGN ERROR"),
                    declined: () => console.log("declined"),
                  },
                }),
              10
            )}
        </div>
      )}
    </div>
  );
};
export default ItemBuy;