export const environment = {
  production: true,
  apiKey:'5f3cd49bf3bc85f2558e6421',
  captchaKey:'6LfV5ccZAAAAACClqoeS8Vv8B_HyXJfd57AaFvKI',
  APIURL: 'https://whale-app-q9zom.ondigitalocean.app',
  headerTitle: 'FCM NFT',
  securityTokenRegistryAddress: '0x289A86045b934b9fE706031D9AD0C0CAaA9F558c',
  allowedNetworks: ['137'], // etherium mainnet
  twoFactorAuth: false,
  ipfsApiKey: '2Wx8JXRnkArvmyWSzQkX3BI1z21',
  ipfsProjectSecret: 'ab235ce3884f81fc9c7269a38c689d45',
  ipfsDomain: 'https://querubinart.infura-ipfs.io',
  loginCaptha: 'true',
  maintenanceMode: 'false',
  acuantUrl: 'https://edna.identitymind.com/edna/applications',
  etheriumPriceAPI: 'https://api.coingecko.com/api/v3/coins/markets?vs_currency=usd&ids=matic-network',
  maxImageDimensionSize: 5500, // this is used for maximum uploaded image dimension size for peocessing watermark on that
  TimeZone: "America/New_York",
  TimeOffset: 300, //Timezone offset for EST in minutes.
  displayTimeFormat: "dddd, MMMM Do YYYY, h:mm:ss a z",
  sessionExpiry: 30 * 24 * 60 * 60 * 1000, // 30 days in millis, should be set to 30 days
  websiteName: "https://www.querubinart.com",
  companyName: "Q-Art",
  beforeGoLive: true,
  registerWithInvitation: false,
  invitationCode: 625134,
  goLiveDate: "March 31, 2023",
  claimTimeInEscrow: 30 * 24 * 60 * 60 * 1000, // 30 days in millis, should be set to 30 days
  bidPercentage: 20,
  resell: true,
  companyLogoUrl: '/img/logo/q-art-logo.png',
  homePageBannerImageUrl: '/img/banner/nft-home-page-banner-fcm.jpg',
  envName: 'qart',
  blockchains: {
    '137': { // etherium mainet
      Platform_Beacon_Proxy_Address:"",
      Platform_Beacon_Address: "",
      AdminRoles_Beacon_Address: "",
      Commissions_Beacon_Address: "",
      WhiteListing_Beacon_Address: "",
      NftFactory_Beacon_Address: "",
      NftMinting_Beacon_Address: "",
      NftTrader_Beacon_Address: "",   
    }
  }
}
