import React, { useContext, useState, useEffect } from "react";
import { useHistory, useParams } from 'react-router-dom';
import { Spin, notification} from 'antd';
import authContext from '../components/Shared/Auth.service';
import { NFTCreatorService } from "../components/services/NFTCreator/NFTCreator.service";
import { createGlobalStyle } from 'styled-components';
import $ from 'jquery'
import { MetamaskService } from "./../components/Shared/Metamask.service";

const GlobalStyles = createGlobalStyle`
  .mainside{
    .connect-wal{
      display: none;
    }
    .logout{
      display: flex;
      align-items: center;
    }
  }
`;

const nftService = new NFTCreatorService();
const useSelectedWalletContext = () =>
  new MetamaskService().useSelectedWalletContext();

const NFTSell = (props) => {

  const { userInfo, setUserInfo } = useContext(authContext);
  const [NFTDetails, setNFTDetails] = useState({});
  const [loading, setLoading] = useState(true);
  const [price, setPrice] = useState();
  const [method, setMethod] = useState('Fixed Price');
  const [bid, setBid] = useState();
  const [startingDate, setStartingDate] = useState();
  const [expirationDate, setExpirationDate] = useState();
  const [inputError, setInputError] = useState(false);
  const { selectedWallet, networkId } = useSelectedWalletContext();

  const { nftId } = useParams();
  const history = useHistory();

  useEffect(() => {
    setTimeout(async ()=>{
        if(!userInfo) {
            history.push('/account/login');
        }else {
            setLoading(true);
            let data;
            if(userInfo?._id) data = (await nftService.getNFTById(nftId, userInfo?._id)).data;
            else data = (await nftService.getNFTById(nftId)).data;
            if(data) {
                setNFTDetails(data);
                if(data.sellingStatus === 'selling') {
                  setTimeout(()=>{
                    setPrice(data.sellingPrice);
                    setBid(data.bid);
                    setStartingDate(data.startingDate);
                    setExpirationDate(data.expirationDate);
                    setMethod(data.method);
                    if(data.method === 'Fixed Price') {
                      handleShow();
                    }else if(data.method === 'Auction') {
                      handleShow1();
                    }else{
                      handleShow2();
                    }
                  }, 800)
                }
            }
            setLoading(false);
        }
    }, 300)
  }, [userInfo]);

  const handleShow = ()=>{
      setMethod('Fixed Price')
      document.getElementById("tab_opt_1")?.classList.add("show");
      document.getElementById("tab_opt_1")?.classList.remove("hide");
      document.getElementById("tab_opt_2")?.classList.remove("show");
      document.getElementById("btn1")?.classList.add("active");
      document.getElementById("btn2")?.classList.remove("active");
      document.getElementById("btn3")?.classList.remove("active");
  }
  const handleShow1 = ()=>{
    setMethod('Auction')
      document.getElementById("tab_opt_1")?.classList.add("hide");
      document.getElementById("tab_opt_1")?.classList.remove("show");
      document.getElementById("tab_opt_2")?.classList.add("show");
      document.getElementById("btn1")?.classList.remove("active");
      document.getElementById("btn2")?.classList.add("active");
      document.getElementById("btn3")?.classList.remove("active");
  }
  const handleShow2 = ()=>{
    setMethod('Open for Bids')
      document.getElementById("tab_opt_1")?.classList.add("hide");
      document.getElementById("tab_opt_2")?.classList.add("hide");
      document.getElementById("tab_opt_1")?.classList.remove("show");
      document.getElementById("tab_opt_2")?.classList.remove("show");
      document.getElementById("btn1")?.classList.remove("active");
      document.getElementById("btn2")?.classList.remove("active");
      document.getElementById("btn3")?.classList.add("active");
  }

  const submitSell = async () =>{
    setInputError(false);

    if(method == 'Fixed Price'){
      if(!price) {
        setInputError(true);
        return;
      }
    }else if(method == 'Auction') {
      if(!bid || !startingDate || !expirationDate) {
        setInputError(true);
        return;
      }
    }
    NFTDetails.method = method;
    NFTDetails.sellingPrice = price;
    NFTDetails.bid = bid;
    NFTDetails.startingDate = startingDate;
    NFTDetails.expirationDate = expirationDate;
    if(!NFTDetails.oldSellingStatus || NFTDetails.oldSellingStatus.length < 1){
      NFTDetails.oldSellingStatus = [{
        method,
        sellingPrice: price,
        bid,
        startingDate: startingDate,
        expirationDate: expirationDate
      }];
    }else {
      NFTDetails.oldSellingStatus = NFTDetails.oldSellingStatus.push({
        method,
        sellingPrice: price,
        bid,
        startingDate: startingDate,
        expirationDate: expirationDate
      })
    }

    NFTDetails.sellingStatus = 'selling';

    NFTDetails.minted = true;

    try {
      let res = await nftService.sellNFT({...NFTDetails, networkId});
      notification.open({
        message: 'Success',
        description: 'NFT Place for selling',
      });
      setTimeout(()=>window.location.reload(), 1000);
    } catch (error) {
      console.error(error);
      notification.open({
        message: 'Error',
        description: 'something went wrong while selling nft',
        duration: 0,
      }); 
    }
  }
    return (
      <div>
        <GlobalStyles/>
        {loading && <Spin size="large" style={{margin: '20px 0', textAlign: 'center'}} />}
        {!loading && (<><section className='jumbotron breadcumb no-bg'>
          <div className='mainbreadcumb'>
            <div className='container'>
              <div className='row m-10-hor'>
                <div className='col-12'>
                  <h1 className='text-center'>Sell Page of NFT</h1>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className='container'>

        <div className="row">
          <div className="col-lg-10 offset-lg-1 mb-5">
              <form id="form-create-item" className="form-border" action="#">
                  <div className="field-set">

                      <div className="spacer-single"></div>

                      <h5>Select method</h5>
                        <div className="de_tab tab_methods">
                            <ul className="de_nav text-left mx-0">
                                <li id='btn1' className="active" onClick={handleShow}><span><i className="fa fa-tag"></i>Fixed Price</span>
                                </li>
                                <li id='btn2' onClick={handleShow1}><span><i className="fa fa-hourglass-1"></i>Auction</span>
                                </li>
                                <li id='btn3' onClick={handleShow2}><span><i className="fa fa-users"></i>Open for Bids</span>
                                </li>
                            </ul>

                            <div className="de_tab_content pt-3">
                      
                              <div id="tab_opt_1">
                                    <h5>Price</h5>
                                    <input type="text" name="item_price" id="item_price" className="form-control" placeholder="enter price for one item (Matic)" value={price} onChange={(e)=>{setPrice(e.target.value)}}/>
                              </div>

                                <div id="tab_opt_2" className='hide'>
                                    <h5>Minimum bid</h5>
                                    <input type="text" name="item_price_bid" id="item_price_bid" className="form-control" placeholder="enter minimum bid" value={bid} onChange={(e)=>{setBid(e.target.value)}}/>

                                    <div className="spacer-20"></div>

                                    <div className="row">
                                        <div className="col-md-6">
                                            <h5>Starting date</h5>
                                            <input type="date" name="bid_starting_date" id="bid_starting_date" className="form-control" min="1997-01-01" value={startingDate} onChange={(e)=>{setStartingDate(e.target.value)}} />
                                        </div>
                                        <div className="col-md-6">
                                            <h5>Expiration date</h5>
                                            <input type="date" name="bid_expiration_date" id="bid_expiration_date" className="form-control" value={expirationDate} onChange={(e)=>{setExpirationDate(e.target.value)}}/>
                                        </div>
                                    </div>
                                </div>

                                <div id="tab_opt_3">
                                </div>

                            </div>

                        </div>

                        <div className="spacer-20"></div>
                        {inputError && (<p style={{color: 'red'}}>Please fill out all the required selling information fields</p>)}

                      <input type="button" id="submit" className="btn-main" value="Create Item" onClick={submitSell}/>
                  </div>
              </form>
          </div>                                         
      </div>

      </section>
      </>)}
      </div>
   );
}

export default NFTSell;